import React from "react"

import { Link } from 'gatsby';

import { EuiEmptyPrompt, EuiButton } from '@elastic/eui';

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <EuiEmptyPrompt
    iconType="editorStrike"
    title={<h2>Опа! Тут ничего нет ...</h2>}
    body={
      <>
        <p>Этой страницы не существует.</p>
      </>
    }
    actions={
      <Link to="/">
        <EuiButton color="primary" fill>
          На главную
        </EuiButton>
      </Link>
    }
  />
  </Layout>
)

export default NotFoundPage




